<template>
    <div>

            <v-toolbar flat>
                <v-btn class="bg-gradient" dark @click="$router.go(-1)" depressed>
                    <v-icon size="20" left>mdi-chevron-left</v-icon>
                    Back
                </v-btn>
            </v-toolbar>

            <v-card-title class="flex justify-space-between align-center" v-if="website">
                <div>{{website.title}}</div>
            </v-card-title>

            <!-- Properties -->
            <v-container>
                <v-row class="px-4">
                    <v-col md="6" cols="12">
                        <v-img
                            v-if="website.website_images"
                            :src="`https://d1o3gwiog9g3w3.cloudfront.net/website/${website.website_images[0].url}`"
                            lazy-src="../../assets/img/bg-grey.svg"
                            aspect-ratio="1.7"
                            cover
                            class="rounded-lg"
                        ></v-img>
                        

                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>Add Price List</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card flat>
                                        <!-- <v-alert text dense color="grey darken-3" border="left">
                                            <v-row class="center py-1" align="center">
                                                <v-col class="grow body-2 grey--text text--darken-3">Image Gallery ({{previewImage.length}})</v-col>
                                                <v-col class="shrink">
                                                    <v-btn outlined x-small class="grey darken-2" dark>
                                                        <label for="gallery">
                                                            Upload
                                                            <input 
                                                                type="file" 
                                                                id="gallery" 
                                                                hidden multiple 
                                                                ref="files" 
                                                                @change="updateImageList"
                                                                accept="image/png, image/jpeg, image/bmp"
                                                                :maxlength="maxLength"
                                                            >
                                                        </label>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-alert> -->

                                        <v-row class="px-2">
                                            <v-col cols="4" v-for="(preview, index) in previewImage" :key="index">
                                                <v-img :src="preview.src" class="align-top rounded-lg" aspect-ratio="1.4">
                                                    <v-btn class="blue-grey darken-4" dark fab x-small tile @click="clearImage(index)">
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                </v-img>
                                            </v-col>
                                            <label for="gallery" class="addNewbtn">
                                                <v-icon>mdi-plus</v-icon>
                                                <input 
                                                    type="file" 
                                                    id="gallery" 
                                                    hidden multiple 
                                                    ref="files" 
                                                    @change="updateImageList"
                                                    accept="image/png, image/jpeg, image/bmp"
                                                    :maxlength="maxLength"
                                                >
                                            </label>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="4" v-for="image in pricegallery" :key="image.id">
                                                <v-img
                                                    :src="`https://d1o3gwiog9g3w3.cloudfront.net/website/${image.url}`"
                                                    aspect-ratio="1.4"
                                                    cover
                                                >
                                                    <v-btn x-small fab tile @click="deletePriceImage(image.id)"><v-icon>mdi-close</v-icon></v-btn>
                                                </v-img>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-btn small block depressed class="mt-6" @click="uploadImage">Save</v-btn>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                    </v-col>
                    <v-col md="6" cols="12">
                        <v-simple-table>
                            <thead><tr class="grey lighten-3"><th>Sharing History</th><th></th></tr></thead>
                            <tbody>
                                <tr class="grey--text text--darken-3">
                                    <td>Total Shared</td>
                                    <td>
                                        {{total}}
                                        <v-btn icon @click="leadListSidebar = !leadListSidebar"><v-icon>mdi-chevron-right</v-icon></v-btn>
                                    </td>
                                </tr>
                                <tr class="grey--text text--darken-3">
                                    <td>Opened</td>
                                    <td>
                                        {{opened}}
                                        <v-btn icon><v-icon>mdi-chevron-right</v-icon></v-btn>
                                    </td>
                                </tr>
                                <tr class="grey--text text--darken-3">
                                    <td>Unopened</td>
                                    <td>
                                        {{unopened}}
                                        <v-btn icon><v-icon>mdi-chevron-right</v-icon></v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <v-btn class="text-capitalize amber accent-3" outlined block @click="dialog = true">Preview</v-btn>

                        <!-- all leads dialog by category -->
                        <v-navigation-drawer v-model="leadListSidebar" tile absolute temporary right width="30vw">
                            <v-card flat tile>
                                <v-list>
                                    <v-list-item v-for="lead in leads" :key="lead.id">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="lead.name"></v-list-item-title>
                                            <v-list-item-subtitle></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>

                            </v-card>
                        </v-navigation-drawer>
                    </v-col>
                </v-row>
            </v-container>


        <!-- Website Preview -->
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card tile>
                <v-toolbar dark color="#111828">
                    <v-toolbar-title v-if="website">{{website.title}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>

                <v-row class="">
                    <v-col md="8" offset-md="2">
                        <v-card class="mx-auto" tile>
                            <v-card-title>{{website.title}}</v-card-title>
                            <v-card-subtitle>{{website.about}}</v-card-subtitle>

                            <v-row class="px-4" v-if="website.website_images">
                                <v-col  v-for="image in website.website_images" :key="image.id" class="px-1 d-flex child-flex" cols="6" md="3">
                                    <v-img
                                        :src="`https://d1o3gwiog9g3w3.cloudfront.net/website/${image.url}`"
                                        lazy-src="../../assets/img/bg-grey.svg"
                                        aspect-ratio="1"
                                        class="white rounded-lg"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-col>
                            </v-row>

                            <v-card class="d-flex align-center pa-4 mt-5 justify-space-around">
                                <div class="font-weight-bold">PREPARED BY:</div>
                                <v-spacer></v-spacer>
                                <v-img max-width="80px" class="rounded-lg mr-3" :src="agent.image"></v-img>
                                <div>
                                    <div>{{agent.name}}</div>
                                    <div>{{agent.contact}}</div>
                                </div>
                            </v-card>

                            <v-card-text>
                                <div class="text-h6">Map</div>
                                <iframe :src="website.map" style="width: 100%; height: 350px; border:0" allowfullscreen="" loading="lazy"></iframe>
                            </v-card-text>
                            <v-card-text class="text-center">
                                <div class="text-h6">Walkthrough</div>
                                <LazyYoutube :src="website.walkthrough" />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-card>
                    <v-card-text class="text-center">
                        <h5>SHARED BY</h5>
                        <v-img max-width="150" :src="agent.brand_logo" class="mx-auto my-3"></v-img>
                        <h3>{{agent.brand_text}}</h3>
                        <h4>{{agent.name}}</h4>
                        <div class="caption">{{agent.contact}}</div>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn outlined class="text-capitalize" color="blue darken-3">
                            <v-icon left>mdi-phone</v-icon>
                            <span>Call</span>
                        </v-btn>
                        <v-btn outlined class="text-capitalize" color="grey darken-1">
                            <v-icon left>mdi-message-processing-outline</v-icon>
                            <span>SMS</span>
                        </v-btn>
                        <v-btn outlined class="text-capitalize" color="teal darken-3">
                            <v-icon left>mdi-whatsapp</v-icon>
                            <span>Whatsapp</span>
                        </v-btn>
                    </v-card-actions>
                    <v-card-text class="text-center">
                        <v-icon>mdi-lightning-bolt-outline</v-icon>
                        Powered By AgentsNest
                    </v-card-text>
                </v-card>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import Website from '../../Apis/Website'
import User from '../../Apis/User'
import { LazyYoutube } from "vue-lazytube";

export default {
    components: {
        LazyYoutube
    },
    data: () => ({
        maxLength: 2,
        website: null,
        pricegallery: [],
        opened: null,
        unopened: null,
        total: null,
        dialog: false,
        agent: '',
        leadListSidebar: false,
        leads: [],
        ytUrl: 'https://www.youtube.com/embed/',
        projectGallery: 0,
        rules: [
            value => !value || value.size < 2000000 || 'File size should be less than 2 MB!',
        ],
        previewImage: [],
        fileName: '',
        fileSize: '',
        pricelist: [],
    }),

    methods:{
        fetchData() {
            var website = this.$route.params.id;
            Website.SlugDetails(website).then((response) => {
                this.website = response.data.website
                this.opened = response.data.opened
                this.unopened = response.data.unopened
                this.total = response.data.total
                this.pricegallery = response.data.pricelists
                // console.log(response.data)
            });
        },
        fetchAgent(){
            User.auth().then(response => {
                this.agent = response.data.data;
                this.loggedIn = true
            });
        },
        clearImage(index){
            this.previewImage.splice(index);
            this.pricelist.splice(index);
        },
        updateImageList(){
            var selectedFiles = this.$refs.files.files;
            for (let i = 0; i < selectedFiles.length; i++) {
                let img = {
                    src: URL.createObjectURL(selectedFiles[i]),
                    file: selectedFiles[i]
                }
                this.previewImage.push(img);
            }
            for (let index = 0; index < selectedFiles.length; index++) {
                this.pricelist.push(selectedFiles[index]);
            }
        },
        uploadImage(){
            let data = new FormData();
            data.append('website_id', this.website.id)
            data.append('type', 'pricelist')
            for (let i = 0; i < this.pricelist.length; i++) {
                let file = this.pricelist[i];
                data.append('files[' + i + ']', file);
            }

            // for (var pair of data.entries()){
            //     console.log(pair[0]+ ', '+ pair[1]); 
            // }

            Website.addNewImages(data)
            .then(() => {
                this.pricelist = []
                this.previewImage = []
                this.fetchData();
            })
        },
        deletePriceImage(websiteimage){
            Website.deletePriceListImage(websiteimage)
            .then(() => {
                this.fetchData();
                window.location.reload();
            })
        }
    },

    mounted(){
        this.fetchData();
        this.fetchAgent();
    },
}
</script>

<style scoped>
.content-card{
  /* overflow-y: scroll; */
}
.search-input{
  background-color: #fff;
  border-radius: 12px;
  padding: 0.3em 1em;
  font-size: 15px;
  max-width: 350px;
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(136,148,171,.2),0 24px 20px -24px rgba(71,82,107,.1);
}
.m-search-input{
  background-color: #fff;
  border-radius: 6px;
  padding: 0.3em 1em;
  font-size: 15px;
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(136,148,171,.2),0 24px 20px -24px rgba(71,82,107,.1);
}
.addNewbtn{
    height: 90px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #efefef;
    margin-top: 0.6em;
}
</style>